import readyHandler from "../lib/readyHandler";

readyHandler.ready(() => {
  const toggle = document.getElementsByClassName("mobile-nav")[0];
  const header = document.getElementsByTagName("header")[0];

  // Toggle mobile
  toggle.addEventListener("click", function (evt: Event) {
    evt.preventDefault();
    header.classList.toggle("show-nav");
  });
});
