import readyHandler from "../lib/readyHandler";

readyHandler.ready(function () {
  [...document.querySelectorAll("nav.subnav")].forEach(
    (subnav: HTMLElement) => {
      const expander = subnav.querySelectorAll(".mobile-expander")[0];

      expander.addEventListener("click", (evt: Event) => {
        evt.preventDefault();
        subnav.classList.toggle("expanded");
      });
    }
  );
});
