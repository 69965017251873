import WindowAnimation from "../lib/WindowAnimation";
import readyHandler from "../lib/readyHandler";

interface Column {
  elem: HTMLElement;
  container: HTMLElement;
  parent: HTMLElement;
  position: string;
  height?: number;
  maxHeight?: number;
  offset?: number;
  top?: number;
}

readyHandler.ready(function () {
  //let topPos = main.getBoundingClientRect().top + window.pageYOffset;
  const topPos = 120;
  let windowHeight = 0;
  let windowWidth = 0;
  const bottomPadding = 120;
  const mobileWidth = 900;

  let columns: Column[] = [];
  [...document.querySelectorAll(".scrolling-columns")].forEach(
    (container: HTMLElement) => {
      [...container.querySelectorAll(".column-content")].forEach(
        (column: HTMLElement) => {
          columns.push({
            elem: column,
            container: container,
            parent: column.parentElement,
            position: "top"
          });
        }
      );
    }
  );

  [...document.querySelectorAll("img")].forEach((img: HTMLImageElement) => {
    img.onload = () => {
      WindowAnimation.queueLayout();
    };
  });

  WindowAnimation.register({
    resize: (width: number, height: number) => {
      windowHeight = height;
      windowWidth = width;
      columns = columns.map((column) => {
        const parentStyle = window.getComputedStyle(column.parent, null);
        const colWidth =
          column.parent.offsetWidth -
          (parseFloat(parentStyle.paddingLeft) +
            parseFloat(parentStyle.paddingRight));

        column.elem.style.width = colWidth + "px";
        column.elem.style.marginTop = "0px";

        // Adjust for rotated heading
        if (column.parent.classList.contains("boxout-heading")) {
          const heading = column.elem.getElementsByTagName("h4")[0];
          if (heading && width > mobileWidth) {
            column.elem.style.minHeight =
              heading.scrollWidth + bottomPadding + "px";
          } else {
            column.elem.style.minHeight = "0px";
          }
        }

        const columnRect = column.elem.getBoundingClientRect();
        const containerRect = column.container.getBoundingClientRect();

        let offset = 0;
        if (columnRect.height > windowHeight - topPos) {
          offset = columnRect.height - windowHeight + topPos;
        }

        return {
          ...column,
          position: "top",
          width: colWidth,
          height: columnRect.height,
          top: containerRect.top + window.pageYOffset,
          offset: offset,
          maxHeight: containerRect.height
        };
      });
    },

    scroll: function (offset) {
      columns.forEach((column) => {
        const prevPosition = column.position;

        // Top aligned
        if (
          windowWidth <= mobileWidth ||
          column.height == column.maxHeight ||
          offset + windowHeight <= column.top + column.height ||
          offset <= column.top - topPos
        ) {
          column.parent.classList.remove("bottom");
          column.parent.classList.remove("fixed");
          column.position = "top";

          // Bottom aligned
        } else if (
          offset + column.height + topPos >=
          column.top + column.maxHeight + column.offset
        ) {
          column.parent.classList.remove("fixed");
          column.parent.classList.add("bottom");
          column.position = "bottom";

          // Fixed
        } else {
          column.parent.classList.remove("bottom");
          column.parent.classList.add("fixed");
          column.position = "fixed";
        }

        if (column.position == "fixed" && prevPosition != "fixed") {
          column.elem.style.marginTop = 0 - column.offset + "px";
        } else if (column.position != "fixed" && prevPosition == "fixed") {
          column.elem.style.marginTop = "0px";
        }
      });
    }
  });
});
