import WindowAnimation from "../lib/WindowAnimation";
import readyHandler from "../lib/readyHandler";

readyHandler.ready(function () {
  const hero = document.getElementsByClassName("hero")[0];
  const header = document.getElementsByTagName("header")[0];
  let headerTop = 0;

  WindowAnimation.register({
    resize: function () {
      if (hero) {
        headerTop = hero.getBoundingClientRect().height;
      }
    },

    scroll: function (offset) {
      if (offset >= headerTop || !hero) {
        header.classList.add("fixed");
      } else {
        header.classList.remove("fixed");
      }
    }
  });
});
