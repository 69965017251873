// Entry point for the build script in your package.json

// Responsive embeds
import responsiveEmbeds from "./lib/responsiveEmbeds";
responsiveEmbeds();

// Window animation
import WindowAnimation from "./lib/WindowAnimation";
WindowAnimation.start();

import "./features/headerAnimation";
import "./features/mobileNav";
import "./features/scrollingColumns";
import "./features/subnav";
